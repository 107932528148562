import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';

import {
  renderError,
  useJsonApi,
  toMessages,
  putJson,
} from '../../../../ts/useApi';
import { TCreateResponse, TFormInputs, TSaveResponse } from './types';
import SaveForm, { formatFormData } from './SaveForm';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { errorToast, successToast } from '../../../../ts/toast';
import SocietyContainer from '../../Societies/Show/SocietyContainer';
import {
  getSocietyContractTypeIdByType,
  TSocietyContractType,
  STATUS_CONTRACTING,
  CONTRACT_TYPE_DIRECT,
  REPRESENTATIVE_CORPORATION,
  REPRESENTATIVE_SOCIETY,
  INVITATION_MAIL_NOT_SENT,
  DOWNLOAD_LEADER_YES,
  IS_REQUIRED_DELIVERY_ADDRESS_NONE,
  NOT_REQUIRED_PRE_CONFIRM,
  PRE_CONFIRMATION_TYPE_NET,
  FLYER_PRE_DELIVERY_TYPE_BEFORE_OPEN,
  PHOTO_ALIGNMENT_DATETIMEORIGINAL,
  PHOTO_ALIGNMENT_PHOTOGRAPHNO,
  AUTHENTICATION_TYPE_YEAR,
  CAMPAIGN_TYPE_OCCASION,
  CAMPAIGN_TYPE_NO,
  FRAME_FOR_GROUP_PHOTO_TYPE_LINE_2L,
  DELIVERY_TYPE_INDIVIDUAL,
  SOCIETY_CONTRACTS_SELECTED_YES,
  SOCIETY_CONTRACTS_SELECTED_NO,
  PHOTOGRAPHY_SUBMIT_TYPE_WEB,
} from '../../../../ts/societyContractConstants';
import SocietyHeader from '../../Societies/Show/SocietyHeader';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { alertApiError } from '../../../../ts/formValidation';
import { dateFormatHyphen } from '../../../../ts/formatTools';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { societyId, societyContractType } = useParams<{
    societyId: string;
    societyContractType: TSocietyContractType;
  }>();
  const societyContractTypeId =
    getSocietyContractTypeIdByType(societyContractType);
  const { data, error } = useJsonApi<TCreateResponse>(
    `/api/societies/${societyId}/society_contracts/create`
  );

  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  const onSubmit: SubmitHandler<TFormInputs> = usePreventDuplicateCall(
    async (formData) => {
      try {
        formatFormData(formData);
        const saveResponse = await putJson<TSaveResponse>(
          `/api/societies/${societyId}/society_contracts`,
          formData
        );
        setErrorMessages([]);
        if (saveResponse.validator.hasError) {
          setValidator(saveResponse.validator);
          alertApiError();
          return;
        }
        successToast('登録しました');
        history.push(`/societies/${societyId}`);
      } catch (e) {
        setErrorMessages(toMessages(e));
        errorToast('エラーが発生しました');
      }
    }
  );

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }
  const {
    society,
    organization,
    societyStudents,
    requestPhotographers,
    societyDirect,
  } = data.data;

  const formItems = data.formItems;

  const today = dateFormatHyphen(Date.now());

  // 直販・先生で共通の初期値
  const commonDefaultValues: Partial<TFormInputs> = {
    status: STATUS_CONTRACTING,
    concludeDate: today!,
    invitationStatus: INVITATION_MAIL_NOT_SENT,
    isEnabledDownloadByLeader: DOWNLOAD_LEADER_YES,
    isRequiredDeliveryAddress: IS_REQUIRED_DELIVERY_ADDRESS_NONE,
    isRequiredPreConfirmation: NOT_REQUIRED_PRE_CONFIRM,
    preConfirmationType: PRE_CONFIRMATION_TYPE_NET,
    isRequiredFlyer: SOCIETY_CONTRACTS_SELECTED_YES,
    flyerPreDeliveryType: FLYER_PRE_DELIVERY_TYPE_BEFORE_OPEN,
    numberOfFlyers: -1,
    numberOfBusinessDaysToPreship: 4,
    isEnabledPublishingPeriods: SOCIETY_CONTRACTS_SELECTED_NO,
    authenticationType: AUTHENTICATION_TYPE_YEAR,
    isEnabledStaffPhotographing: SOCIETY_CONTRACTS_SELECTED_NO,
    isEnabledDisplayPartnerName: SOCIETY_CONTRACTS_SELECTED_NO,
    numberOfFaxApplicationForms: 0,
    isEnabledDisplayPersonalInformationToPartner: SOCIETY_CONTRACTS_SELECTED_NO,
    isEnabledFaceRecommend: SOCIETY_CONTRACTS_SELECTED_NO,
    isSalablePhotopanel: SOCIETY_CONTRACTS_SELECTED_NO,
    isEnabledFaxCommission: SOCIETY_CONTRACTS_SELECTED_YES,
    isEnabledPostagePaidForIndividualdelivery: SOCIETY_CONTRACTS_SELECTED_YES,
    isEnabledFreePhoto: SOCIETY_CONTRACTS_SELECTED_NO,
    isEnabledAutoEventCreate: SOCIETY_CONTRACTS_SELECTED_NO,
    frameForGroupPhotoType: FRAME_FOR_GROUP_PHOTO_TYPE_LINE_2L,
    deliveryType: DELIVERY_TYPE_INDIVIDUAL,
    isRequiredAddressesForBulkdelivery: SOCIETY_CONTRACTS_SELECTED_YES,
    isLegalEntity: 1,
    isEnabledAutoEventPeriodSetting: 0,
    bulkdeliveryInfo: {
      carriage: 0,
      closeDay: 1,
      deliveryAddressType: 2, // 団体
    },
    salesManagement: {
      isEnabledSellByFax: 0,
      isEnabledSellDataS: 0,
      printingCompanyPhotoCorrectionType: 0,
      isEnabledAlbumSales: 0,
      isEnabledPhotoSelect: 0,
    },
  };
  const commonDefaultValuesDirectContracts = {
    isRequiredSamplebookForPreConfirmation: SOCIETY_CONTRACTS_SELECTED_NO,
    isRequiredFax: SOCIETY_CONTRACTS_SELECTED_NO,
    isRequiredDvd: SOCIETY_CONTRACTS_SELECTED_NO,
    isEnabledPointSales: SOCIETY_CONTRACTS_SELECTED_NO,
    salesDepartmentOwnerno: 0,
    secondarySalesDepartmentOwnerno: 0,
  };

  // 直販の初期値
  const makeDirectDefaultValues = () => ({
    ...commonDefaultValues,
    societyContractTypeId,
    representativeType: REPRESENTATIVE_SOCIETY,
    isEnabledFlyerPreDelivery: SOCIETY_CONTRACTS_SELECTED_YES,
    photoAlignment: PHOTO_ALIGNMENT_PHOTOGRAPHNO,
    isRequiredSamplebook: SOCIETY_CONTRACTS_SELECTED_NO,
    campaignType: CAMPAIGN_TYPE_OCCASION,
    isEnabledToDisplayModelRecruitmentForm: SOCIETY_CONTRACTS_SELECTED_YES,
    isEnabledDisplayAgreementForLeader: SOCIETY_CONTRACTS_SELECTED_YES,
    isEnabledAlbumSales: SOCIETY_CONTRACTS_SELECTED_YES,
    isEnabledFrameForGroupPhoto: SOCIETY_CONTRACTS_SELECTED_YES,
    societyDirectContract: {
      ...commonDefaultValuesDirectContracts,
      photographySubmitType: PHOTOGRAPHY_SUBMIT_TYPE_WEB,
      isAutoCreateEventSchedulePdf: SOCIETY_CONTRACTS_SELECTED_YES,
    },
  });

  // 先生の初期値
  const makeTeacherDefaultValues = () => ({
    ...commonDefaultValues,
    societyContractTypeId,
    representativeType: REPRESENTATIVE_CORPORATION,
    isEnabledFlyerPreDelivery: SOCIETY_CONTRACTS_SELECTED_NO,
    photoAlignment: PHOTO_ALIGNMENT_DATETIMEORIGINAL,
    isRequiredSamplebook: SOCIETY_CONTRACTS_SELECTED_YES,
    campaignType: CAMPAIGN_TYPE_NO,
    isEnabledToDisplayModelRecruitmentForm: SOCIETY_CONTRACTS_SELECTED_NO,
    isEnabledDisplayAgreementForLeader: SOCIETY_CONTRACTS_SELECTED_NO,
    isEnabledAlbumSales: SOCIETY_CONTRACTS_SELECTED_NO,
    isEnabledFrameForGroupPhoto: SOCIETY_CONTRACTS_SELECTED_NO,
    societyDirectContract: {
      ...commonDefaultValuesDirectContracts,
      photographySubmitType: PHOTOGRAPHY_SUBMIT_TYPE_WEB,
      isAutoCreateEventSchedulePdf: SOCIETY_CONTRACTS_SELECTED_NO,
    },
    isHighResolution: false,
  });

  const makeDefaultValues = (societyContractTypeId: number) =>
    societyContractTypeId === CONTRACT_TYPE_DIRECT
      ? makeDirectDefaultValues()
      : makeTeacherDefaultValues();

  const defaultValues = {
    ...makeDefaultValues(societyContractTypeId),
  };

  return (
    <div className="p-groupsSummary c-summary">
      <h1 className="l-flex_center u-mgb_m c-page_title">
        団体契約 - 新規登録
      </h1>
      <div className="l-content">
        <div className="l-center_wrap">
          <div className="c-frame">
            <SocietyHeader society={society} organization={organization} />
            <div className="u-mgt_s l-flex nowrap">
              <SocietyContainer
                society={society}
                organization={organization}
                societyDirect={societyDirect}
                societyStudents={societyStudents}
                requestPhotographers={requestPhotographers}
              />
            </div>
          </div>
          <div className="l-flex_between_center u-mgb_s">
            <h4 className="c-section_title">契約情報</h4>
          </div>
          <SaveForm
            validator={validator}
            formItems={formItems}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            errorMessages={errorMessages}
            submitButton="登録する"
            cancelLink={`/societies/${society.id}`}
            isEditing={false}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
