import {
  Resolver,
  SubmitHandler,
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import { NgPairAddFormParams } from '../../hooks';
import { addNgPairValidationSchema } from './validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';

type UsePhotoIdSearchReturn = {
  formMethods: UseFormReturn<NgPairAddFormParams>;
  handleSubmitWrapper: SubmitHandler<NgPairAddFormParams>;
};

export const useAddNgPair = (
  doSubmit: SubmitHandler<NgPairAddFormParams>
): UsePhotoIdSearchReturn => {
  const formMethods = useForm<NgPairAddFormParams>({
    resolver: zodResolver(
      addNgPairValidationSchema
    ) as Resolver<NgPairAddFormParams>,
    defaultValues: {
      photographerId: undefined,
      targetPhotographerId: undefined,
    },
  });

  const handleSubmitWrapper = useCallback<SubmitHandler<NgPairAddFormParams>>(
    async (values: NgPairAddFormParams) => {
      await doSubmit(values);
      formMethods.reset();
    },
    [doSubmit, formMethods]
  );

  return {
    formMethods,
    handleSubmitWrapper,
  };
};
