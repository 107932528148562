import { PageContainer } from '@/components/shared/PageContainer';
import React from 'react';
import { ResultPanel } from './components/ResultPanel';
import { useNgPairs } from './hooks';
import { ErrorPanel } from '@/components/shared/Form/Errors';
import { AddPanel } from './components/AddPanel';
import { Notice } from '@/components/shared/Notice';
import { LoadingCover } from '@/components/shared/LoadingCover';

export const PhotographersNgPairs = React.memo(function PhotographersNgPairs() {
  const {
    results,
    loading,
    creating,
    removing,
    errorMessages,
    successMessage,
    handleAddSubmit,
    handleRemoveSubmit,
    handleCloseNotice,
  } = useNgPairs();

  return (
    <PageContainer title="カメラマンNG組み合わせ一覧">
      {(loading || removing) && <LoadingCover />}
      {<ErrorPanel messages={errorMessages} />}
      {successMessage && (
        <Notice
          type="success"
          message={successMessage}
          onClose={handleCloseNotice}
        />
      )}
      <AddPanel loading={creating} doSubmit={handleAddSubmit} />
      <ResultPanel ngPairs={results} doSubmit={handleRemoveSubmit} />
    </PageContainer>
  );
});
