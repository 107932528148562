import React from 'react';
import styles from './index.module.scss';
import clsx from 'clsx';

export const LoadingCover = React.memo(() => {
  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.progress, styles.loader)} />
    </div>
  );
});
