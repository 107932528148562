import React from 'react';
import { NgPair, NgPairPhotographer } from '../../types';
import { ContentPanel } from '@/components/shared/ContentPanel';
import { Table, TableRow } from '@/components/shared/Table';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useRemoveNgPair } from './hooks';
import { NgPairRemoveFormParams } from '../../hooks';
import clsx from 'clsx';
import { Dialog } from '@/components/shared/Dialog';

type Props = {
  ngPairs?: NgPair[];
  doSubmit: SubmitHandler<NgPairRemoveFormParams>;
};

const columns = ['カメラマン', 'NGなカメラマン'];
const columnsParam = ['200px', 'minmax(400px, 1fr)'];

const getCaption = (photographer: NgPairPhotographer) =>
  `${photographer.id}: ${photographer.lastName} ${photographer.firstName}`;

export const ResultPanel = React.memo<Props>(function ResultPanel({
  ngPairs,
  doSubmit,
}) {
  const {
    open,
    dialogMessage,
    handleClose,
    handleOpen,
    formMethods,
    handleRemove,
  } = useRemoveNgPair(doSubmit);

  if (!ngPairs || ngPairs?.length === 0) {
    return null;
  }

  const { register } = formMethods;

  return (
    <ContentPanel marginTop="large">
      <FormProvider {...formMethods}>
        <Table
          columns={columns}
          rows={
            ngPairs.map(
              (ngPair): TableRow => [
                `${ngPair.photographer.id}: ${ngPair.photographer.lastName} ${ngPair.photographer.firstName}`,
                <span>
                  {ngPair.targets.map((target) => (
                    <label
                      className="c-checkboxLabel"
                      key={`ngPair-${ngPair.photographer.id}-${target.id}`}
                    >
                      <input
                        type="checkbox"
                        {...register('id', { value: ngPair.id })}
                        value={ngPair.id}
                        className={clsx('c-checkbox')}
                        onChange={(e) => {
                          handleOpen(
                            ngPair.id,
                            `「${getCaption(
                              ngPair.photographer
                            )}」と「${getCaption(
                              target
                            )}」のNG組み合わせを削除して良いですか？`
                          );
                        }}
                      />
                      <span className={clsx('c-label_checkbox small')}>
                        {target.id}: {target.lastName} {target.firstName}
                      </span>
                    </label>
                  ))}
                </span>,
              ]
            ) || []
          }
          gridTemplateColumns={columnsParam}
        />
        <Dialog
          open={open}
          body={dialogMessage || ''}
          onClose={handleClose}
          onSubmit={handleRemove}
        />
      </FormProvider>
    </ContentPanel>
  );
});
