import React from 'react';
import styles from './Button.module.scss';
import clsx from 'clsx';

type Props = {
  value: string;
  type: 'submit' | 'button';
  variant?: 'search' | 'outline'; // TODO: 見た目の種類を増やす
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
export const Button = React.memo<Props>(function Button({
  value,
  type,
  variant,
  loading = false,
  onClick,
}) {
  return (
    <button
      className={clsx(
        styles.submit,
        variant ? styles[variant] : undefined,
        styles.small
      )}
      type={type}
      value={value}
      onClick={onClick}
      disabled={loading}
    >
      {loading ? <div className={styles.loader} /> : value}
    </button>
  );
});
