import React from 'react';
import { HYPHEN, numberFormat } from '../../../../ts/formatTools';
import { isUserTypeGeneral } from './constants';
import { calcTaxInPrice } from './price';
import { NumberInput } from '@/components/shared/Form/Inputs';
import {
  TValidatorResponse,
  TOnlyValidationRuleResponse,
} from '@/components/shared/Form/types';
import { useFormContext } from 'react-hook-form';
import { FormError } from '@/components/shared/Form/Errors';

const displayPrice = (price: number | undefined | null) =>
  price && !isNaN(price) ? `¥${numberFormat(price)}` : '';
const displayTaxPrice = (price: number | undefined) =>
  price && !isNaN(price) ? `(¥${numberFormat(price)})` : '';

const PriceInput: React.FC<{
  name: string;
  price: number | undefined | null;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  taxRate: number;
  showTaxIncludedPrice: boolean;
}> = React.memo(({ name, price, validator, taxRate, showTaxIncludedPrice }) => {
  const taxInPrice = calcTaxInPrice(price, taxRate);
  return (
    <>
      <NumberInput
        name={name}
        additionalClassName="p-priceInput u-align_right"
        validator={validator}
      />
      {showTaxIncludedPrice && (
        <p>({taxInPrice ? displayPrice(taxInPrice) : HYPHEN})</p>
      )}
    </>
  );
});

const AssociatedPriceDisplay: React.FC<{
  name: string;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  price: number | undefined | null;
  taxRate: number;
  showTaxIncludedPrice: boolean;
}> = React.memo(({ name, validator, price, taxRate, showTaxIncludedPrice }) => {
  const taxInPrice = calcTaxInPrice(price, taxRate);
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <p>{displayPrice(price)}</p>
      <FormError name={name} errors={errors} validator={validator} />
      {showTaxIncludedPrice && <p>{displayTaxPrice(taxInPrice)}</p>}
    </>
  );
});

const Prices: React.FC<{
  name: string;
  isEnabled: boolean;
  price: number | undefined | null;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  isAutoCalcAssociated: boolean;
  userTypeId: number;
  taxRate: number;
  showTaxIncludedPrice: boolean;
}> = React.memo(
  ({
    name,
    isEnabled,
    price,
    validator,
    isAutoCalcAssociated,
    userTypeId,
    taxRate,
    showTaxIncludedPrice,
  }) =>
    isEnabled ? (
      !isUserTypeGeneral(userTypeId) && isAutoCalcAssociated ? (
        <AssociatedPriceDisplay
          name={name}
          price={price}
          validator={validator}
          taxRate={taxRate}
          showTaxIncludedPrice={showTaxIncludedPrice}
        />
      ) : (
        <PriceInput
          name={name}
          price={price}
          validator={validator}
          taxRate={taxRate}
          showTaxIncludedPrice={showTaxIncludedPrice}
        />
      )
    ) : (
      <>{HYPHEN}</>
    )
);

export default Prices;
