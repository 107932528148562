import React, { useCallback, useState, useRef } from 'react';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';

import PricesList from './PricesList';
import SaveForm from './SaveForm';
import { TFormInputs, TPriceInput, TPricePattern } from './types';
import {
  makeDefaultSizeChoices,
  getPhotoSizeNameById,
  initializePriceInput,
} from './priceSettings';
import {
  isPhotoTypeSnap,
  isPriceNotForSale,
  PHOTO_SIZE_ID_2L,
  PHOTO_SIZE_ID_2LW,
  PHOTO_SIZE_ID_DVD,
  PHOTO_SIZE_ID_L,
} from './constants';
import './style.scss';
import {
  TPhotoSize,
  TUserType,
  TPhotoType,
} from '../../../shared/PriceTable/types';
import { TPriceIsEnabledList, TPriceDefaultPrices } from './types';
import { FormProvider, useForm } from 'react-hook-form';
import { ManualAccordion } from '../../../shared/Accordion/App';

type Props = {
  savePath: string;
  updatePathPrefix: string;
  isPhoto: boolean;
  incentivePaymentType: number | null;
  pricePatterns: TPricePattern[];
  userTypes: TUserType[];
  photoTypes: TPhotoType[];
  photoSizes: TPhotoSize[];
  sizeNames: string[];
  isEnabledList: TPriceIsEnabledList;
  defaultPrices: TPriceDefaultPrices;
  snapDefaultSizeNames: string[];
  groupDefaultSizeNames: string[];
  taxRate: number;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  teacherFlag: boolean;
  onUpdate: () => void;
} & (
  | {
      enableInputTaxIncludedPrice: true;
      defaultPricesTaxIncluded: TPriceDefaultPrices;
    }
  | { enableInputTaxIncludedPrice: false; defaultPricesTaxIncluded?: undefined }
);

const App: React.FC<Props> = React.memo(
  ({
    savePath,
    updatePathPrefix,
    isPhoto,
    incentivePaymentType,
    pricePatterns,
    userTypes,
    photoTypes,
    photoSizes: orgPhotoSizes,
    sizeNames,
    isEnabledList,
    defaultPrices,
    snapDefaultSizeNames,
    groupDefaultSizeNames,
    taxRate,
    validator,
    onUpdate,
    enableInputTaxIncludedPrice,
    defaultPricesTaxIncluded,
    teacherFlag,
  }) => {
    const [isFormOpen, setIsFormOpen] = useState(false);
    const formToggleButtonRef = useRef<HTMLButtonElement>(null);

    // NOTE: 必要なサイズのみ表示
    const photoSizes = orgPhotoSizes.filter((photoSize) =>
      sizeNames.includes(photoSize.sizename)
    );

    const basicSizeOfSnap = isPhoto ? PHOTO_SIZE_ID_L : PHOTO_SIZE_ID_DVD;
    const snapDefaultSizeName = getPhotoSizeNameById(
      photoSizes,
      basicSizeOfSnap
    )!;
    const basicSizeOfGroup = isPhoto
      ? teacherFlag
        ? PHOTO_SIZE_ID_2LW
        : PHOTO_SIZE_ID_2L
      : PHOTO_SIZE_ID_2LW;
    const groupDefaultSizeName = getPhotoSizeNameById(
      photoSizes,
      basicSizeOfGroup
    )!;
    const prices: TPriceInput[] = [];
    userTypes.forEach(({ usertypemasterno }) =>
      photoTypes.forEach(({ phototypemasterno }) =>
        photoSizes.forEach(({ photosizemasterno }) =>
          prices.push(
            initializePriceInput(
              isEnabledList,
              photoSizes,
              isPhotoTypeSnap(phototypemasterno)
                ? snapDefaultSizeName
                : groupDefaultSizeName,
              usertypemasterno,
              phototypemasterno,
              photosizemasterno,
              teacherFlag
            )
          )
        )
      )
    );
    const methods = useForm<TFormInputs>({
      defaultValues: {
        memo: '',
        basicSizeOfSnap,
        basicSizeOfGroup,
        prices: prices,
        isCalculatedByTaxIncludedPrice: 0,
      },
    });

    const snapDefaultSizeChoices = makeDefaultSizeChoices(
      orgPhotoSizes,
      snapDefaultSizeNames
    );
    const groupDefaultSizeChoices = makeDefaultSizeChoices(
      orgPhotoSizes,
      groupDefaultSizeNames
    );

    // 「この価格をもとに新規追加」
    const setBasePrices = useCallback(
      (pricePatternId: number) => {
        if (
          !window.confirm(
            `価格No.${pricePatternId}の内容で新規追加フォームを上書きします。よろしいですか？`
          )
        ) {
          return;
        }

        const basePricePattern = pricePatterns.find(
          (pp) => pp.pricepatternno === pricePatternId
        );
        if (!basePricePattern) {
          // NOTE: 基本的にない
          return;
        }
        const { pricepatterncategories } = basePricePattern;
        // NOTE: pricePatternCategory はユーザー種類＋写真種類ごとに存在し、基本4レコードある
        // が、photoSizeは写真種類で決定するので、ユーザー種類ごとには同じ値が入っている
        const baseSnapDefaultPhotoSize = pricepatterncategories.find((pc) =>
          isPhotoTypeSnap(pc.phototypemasterno)
        )?.photoSize;
        const baseGroupDefaultPhotoSize = pricepatterncategories.find(
          (pc) => !isPhotoTypeSnap(pc.phototypemasterno)
        )?.photoSize;
        const copiedPrices: TPriceInput[] = [];
        userTypes.forEach(({ usertypemasterno }) =>
          photoTypes.forEach(({ phototypemasterno }) => {
            const basePpCategory = pricepatterncategories.find(
              (pc) =>
                pc.usertypemasterno === usertypemasterno &&
                pc.phototypemasterno === phototypemasterno
            );
            const baseDefaultSizeName = isPhotoTypeSnap(phototypemasterno)
              ? baseSnapDefaultPhotoSize?.sizename
              : baseGroupDefaultPhotoSize?.sizename;
            const defaultSizeName = isPhotoTypeSnap(phototypemasterno)
              ? snapDefaultSizeName
              : groupDefaultSizeName;
            photoSizes.forEach(({ photosizemasterno }) => {
              const basePrice = basePpCategory
                ? basePpCategory.prices.find(
                    (p) => p.photosizemasterno === photosizemasterno
                  )
                : undefined;
              const newPrice = initializePriceInput(
                isEnabledList,
                photoSizes,
                baseDefaultSizeName ?? defaultSizeName,
                usertypemasterno,
                phototypemasterno,
                photosizemasterno,
                teacherFlag
              );
              if (basePrice) {
                // もととなるデータがある
                // NOTE: 価格の有効無効は基本サイズで判定し、価格のみコピーもとからコピー
                copiedPrices.push({
                  ...newPrice,
                  price: isPriceNotForSale(basePrice.priceInInteger)
                    ? null
                    : basePricePattern.isCalculatedByTaxIncludedPrice === 1
                    ? basePrice.taxInPrice
                    : basePrice.priceInInteger,
                });
              } else {
                // もととなるデータがない
                copiedPrices.push(newPrice);
              }
            });
          })
        );

        methods.reset({
          memo: '',
          basicSizeOfSnap: baseSnapDefaultPhotoSize?.photosizemasterno ?? 0,
          basicSizeOfGroup: baseGroupDefaultPhotoSize?.photosizemasterno ?? 0,
          prices: copiedPrices,
          isCalculatedByTaxIncludedPrice:
            basePricePattern.isCalculatedByTaxIncludedPrice,
        });
        setIsFormOpen(true);
        formToggleButtonRef.current?.focus();
      },
      [
        methods,
        pricePatterns,
        isEnabledList,
        userTypes,
        photoTypes,
        photoSizes,
        snapDefaultSizeName,
        groupDefaultSizeName,
        teacherFlag,
      ]
    );

    return (
      <>
        <div className="c-frame">
          <div className="l-flex_between_center u-mgb_s">
            <p className="c-text_resultNumber">
              <span>新規登録</span>
            </p>
          </div>
          <FormProvider {...methods}>
            <ManualAccordion
              labelOpen="開く"
              setIsOpen={setIsFormOpen}
              isOpen={isFormOpen}
              toggleButtonRef={formToggleButtonRef}
            >
              <SaveForm
                savePath={savePath}
                taxRate={taxRate}
                validator={validator}
                formItems={{
                  photoSizes,
                  photoTypes,
                  userTypes,
                }}
                isEnabledList={isEnabledList}
                defaultPrices={defaultPrices}
                defaultPricesTaxIncluded={
                  defaultPricesTaxIncluded || defaultPrices
                }
                incentivePaymentType={incentivePaymentType}
                snapDefaultSizeChoices={snapDefaultSizeChoices}
                groupDefaultSizeChoices={groupDefaultSizeChoices}
                enableInputTaxIncludedPrice={enableInputTaxIncludedPrice}
                teacherFlag={teacherFlag}
              />
            </ManualAccordion>
          </FormProvider>
        </div>
        <div className="c-frame">
          <div className="l-flex_between_center u-mgb_s">
            <p className="c-text_resultNumber">
              <span>登録済み価格一覧</span>
            </p>
          </div>
          ※（）内は税込価格になります
          <PricesList
            updatePathPrefix={updatePathPrefix}
            pricePatterns={pricePatterns}
            userTypes={userTypes}
            photoTypes={photoTypes}
            photoSizes={photoSizes}
            sizeNames={sizeNames}
            setBasePrices={setBasePrices}
            validator={validator}
            onUpdate={onUpdate}
            showIsCalculatedByTaxIncludedPrice={enableInputTaxIncludedPrice}
          />
        </div>
      </>
    );
  }
);

export default App;
