import { z } from '@/validation';

export const addNgPairValidationSchema = z.object({
  photographerId: z
    .number()
    .or(z.string().min(1, { message: '必須項目です' }))
    .pipe(z.coerce.number()),
  targetPhotographerId: z
    .number()
    .or(z.string().min(1, { message: '必須項目です' }))
    .pipe(z.coerce.number()),
});
