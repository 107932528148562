import { useEffect, useRef } from 'react';

type Props = {
  open: boolean;
};
type UseDialogReturn = {
  dialogRef: React.RefObject<HTMLDialogElement>;
};

export const useDialog = ({ open }: Props): UseDialogReturn => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const element = dialogRef.current;
    if (!element) {
      return;
    }

    if (open) {
      if (!element.open) {
        element.showModal();
      }
    } else {
      if (element.open) {
        element.close();
      }
    }
  }, [open]);

  return {
    dialogRef,
  };
};
