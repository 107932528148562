import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { NgPairRemoveFormParams } from '../../hooks';
import { useCallback, useState } from 'react';

type UsePhotoIdSearchReturn = {
  open: boolean;
  dialogMessage: string | undefined;
  handleOpen: (id: number, title: string) => void;
  handleClose: () => void;
  handleRemove: () => void;
  formMethods: UseFormReturn<NgPairRemoveFormParams>;
};

export const useRemoveNgPair = (
  doSubmit: SubmitHandler<NgPairRemoveFormParams>
): UsePhotoIdSearchReturn => {
  const [id, setId] = useState<number>();
  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState<string>();
  const formMethods = useForm<NgPairRemoveFormParams>({
    defaultValues: {
      id: undefined,
    },
  });

  const handleOpen = useCallback((id: number, title: string) => {
    setId(id);
    setDialogMessage(title);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    formMethods.reset();
    setOpen(false);
  }, [formMethods]);

  const handleRemove = useCallback(async () => {
    if (!id) return;

    setOpen(false);
    await doSubmit({ id });
    formMethods.reset();
  }, [doSubmit, formMethods, id]);

  return {
    open,
    dialogMessage,
    handleOpen,
    handleClose,
    handleRemove,
    formMethods,
  };
};
