import { fetcher } from '@/ts/fetch';
import { toMessages } from '@/ts/useApi';
import { useCallback, useState } from 'react';
import { useAsync, useAsyncFn } from 'react-use';
import {
  NgPair,
  NgPairAddAPIResponse,
  NgPairAPIResponse,
  NgPairRemoveAPIResponse,
} from './types';
import { SubmitHandler } from 'react-hook-form';

export type NgPairAddFormParams = {
  photographerId: number;
  targetPhotographerId: number;
};

export type NgPairRemoveFormParams = {
  id: number;
};

type UseNgPairReturn = {
  loading: boolean;
  creating: boolean;
  removing: boolean;
  errorMessages?: string[];
  successMessage?: string;
  results?: NgPair[];
  handleAddSubmit: SubmitHandler<NgPairAddFormParams>;
  handleRemoveSubmit: SubmitHandler<NgPairRemoveFormParams>;
  handleCloseNotice: () => void;
};

export const useNgPairs = (): UseNgPairReturn => {
  const [errorMessages, setErrorMessages] = useState<string[] | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const [results, setResults] = useState<NgPair[]>();

  const [{ loading }, handleSearch] = useAsyncFn(async () => {
    try {
      const response = await fetcher<NgPairAPIResponse>(
        '/api/photographers/ng_pairs'
      );

      if (!response.success) {
        throw new Error('Failed to fetch data');
      }

      setResults(response.data);
      setErrorMessages(undefined);
    } catch (e: unknown) {
      setResults(undefined);
      setErrorMessages(toMessages(e));
    }
  }, []);

  useAsync(async () => {
    await handleSearch();
  }, [handleSearch]);

  const [{ loading: creating }, handleAddSubmit] = useAsyncFn<
    SubmitHandler<NgPairAddFormParams>
  >(async (values: NgPairAddFormParams) => {
    try {
      setErrorMessages(undefined);
      const response = await fetcher<NgPairAddAPIResponse>(
        '/api/photographers/ng_pairs',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        }
      );
      if (!response.success) {
        setErrorMessages(
          Object.keys(response.messages)
            .flatMap((key) => response.messages[key])
            .flat()
        );

        return;
      }

      await handleSearch();
    } catch (e: unknown) {
      setErrorMessages(toMessages(e));
    }
  }, []);

  const [{ loading: removing }, handleRemoveSubmit] = useAsyncFn<
    SubmitHandler<NgPairRemoveFormParams>
  >(async (values: NgPairRemoveFormParams) => {
    try {
      setErrorMessages(undefined);
      const response = await fetcher<NgPairRemoveAPIResponse>(
        '/api/photographers/ng_pairs',
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        }
      );
      if (!response.success) {
        setErrorMessages(
          Object.keys(response.messages)
            .flatMap((key) => response.messages[key])
            .flat()
        );

        return;
      }

      setSuccessMessage('削除しました');
      await handleSearch();
    } catch (e: unknown) {
      setErrorMessages(toMessages(e));
    }
  }, []);

  const handleCloseNotice = useCallback(() => {
    setSuccessMessage(undefined);
  }, []);

  return {
    loading: loading && results === undefined, // loadingは初期表示時のみ
    creating,
    removing,
    errorMessages,
    successMessage,
    results,
    handleAddSubmit,
    handleRemoveSubmit,
    handleCloseNotice,
  };
};
