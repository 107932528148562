import React from 'react';
import { toast, ToastContent } from 'react-toastify';
import { TValidatorMessages } from '@/components/shared/Form/types';
import { flattenValidatorMessages } from '@/components/shared/Form/Errors';

export const successToast = (msg: ToastContent): React.ReactText =>
  toast(msg, { type: 'success' });
export const errorToast = (msg: ToastContent): React.ReactText =>
  toast(msg, { type: 'error' });
export const infoToast = (
  msg: ToastContent,
  autoClose: number | undefined = 5000
): React.ReactText =>
  toast(msg, {
    type: 'info',
    autoClose: autoClose,
  });
export const asyncProcessStartToast = (processName: string): React.ReactText =>
  successToast(
    `${processName}を開始しました。結果はSlackの #8122_事業部all チャンネルに通知されます。`
  );

export const errorToastWithValidatorMessages = (
  msg: string,
  errorMessages: TValidatorMessages
): React.ReactText => {
  const Message: React.FC<{ msg: string }> = ({ msg }) => (
    <>
      {msg}
      <br />
    </>
  );
  const Messages = (
    <>
      {msg}
      <br />
      {flattenValidatorMessages(errorMessages).map((m) => (
        <Message key={m} msg={m} />
      ))}
    </>
  );
  return errorToast(Messages);
};
