import clsx from 'clsx';
import styles from './index.module.scss';
import { FaCircleInfo } from 'react-icons/fa6';

type Props = {
  type: 'error' | 'warning';
  title: string;
  children?: React.ReactNode;
  size?: 'small';
};

export const Alert: React.FC<Props> = ({ type, title, children, size }) => {
  return (
    <div
      className={clsx(
        styles.panel,
        styles[type],
        size === 'small' && styles.panelSmall
      )}
    >
      <div
        className={clsx(
          styles.title,
          size === 'small' && styles.titleSmall,
          children !== undefined && styles.titleWithChildren
        )}
      >
        <FaCircleInfo className={styles.icon} />
        {title}
      </div>
      <>{children}</>
    </div>
  );
};
