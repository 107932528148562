import React from 'react';
import { DeepMap, FieldError, FieldErrors, FieldValues } from 'react-hook-form';
import {
  TOnlyValidationRuleResponse,
  TValidator,
  TValidatorMessage,
  TValidatorMessages,
  TValidatorResponse,
} from './types';

type IFormError = {
  key: string;
  error: FieldError;
};
const FormError: React.FC<{
  name: string;
  errors: FieldErrors;
  validator: TValidator;
}> = ({ name, errors, validator }) => {
  const messages = getErrorsForInput(name, errors, validator);

  return messages.length ? (
    <ul className="c-inputError">
      {messages.map((message, index) => (
        <li className="c-inputError_item" key={index}>
          {message}
        </li>
      ))}
    </ul>
  ) : null;
};

const ApiErrors: React.FC<TValidatorResponse | TOnlyValidationRuleResponse> =
  React.memo(({ messages, hasError }) => {
    if (!messages || !hasError) {
      return <></>;
    }

    // 再帰的に読み込んでフラット化する

    return (
      <div className="c-error_block">
        <ul className="c-error_list">
          {flattenValidatorMessages(messages).map((message, index) => (
            <li className="c-error_listItem" key={index}>
              {message}
            </li>
          ))}
        </ul>
      </div>
    );
  });

export const ErrorPanel: React.FC<{ messages?: string[] }> = React.memo(
  ({ messages }) => {
    if (!messages) {
      return <></>;
    }

    return (
      <div className="c-error_block">
        <ul className="c-error_list">
          {messages.map((message, index) => (
            <li className="c-error_listItem" key={index}>
              {message}
            </li>
          ))}
        </ul>
      </div>
    );
  }
);

/**
 * get form error
 */
const getError = (
  errors: FieldErrors,
  key: string
): DeepMap<FieldValues, FieldError> | undefined =>
  key
    .split('.')
    .reduce((acc, k) => (acc && k in acc ? acc[k] : undefined), errors);

type TMessages = string | TValidatorMessage | undefined;
/**
 * get api error
 */
const getApiErrorMessages = (
  messages: TValidatorMessages,
  key: string
): TMessages =>
  key.split('.').reduce((acc: TMessages, k: string | number) => {
    if (!acc) {
      return undefined;
    }
    if (Array.isArray(acc)) {
      return acc[k as number];
    }
    if (typeof acc === 'object' && k in acc) {
      return acc[k as string];
    }
    return undefined;
  }, messages);

/**
 * form & API error messages
 */
const getErrorsForInput = (
  name: string,
  errors: FieldErrors,
  validator: TValidator
): string[] => {
  // front formのvalidationエラー
  const formError = getError(errors, name);
  const formMessages = formError ? getFormErrorMessages(formError) : [];

  // apiのvalidationエラー
  if (!validator.hasError || !validator.messages) {
    return formMessages;
  }
  const apiError = getApiErrorMessages(validator.messages, name);
  const apiMessages = !apiError
    ? []
    : typeof apiError === 'string'
    ? [apiError]
    : flattenValidatorMessages(apiError);

  return formMessages.concat(apiMessages);
};
/**
 * API error messages
 */
const getFormErrorMessages = (errors: FieldErrors): string[] =>
  flattenFormError(errors)
    .filter((e) => e.error.message !== undefined)
    .map((e) => e.error.message!);
/**
 * nestしたform errorをflatにする
 */
const flattenFormError = (
  messages: FieldErrors,
  keysString = 'err'
): IFormError[] => {
  if (Array.isArray(messages)) {
    return messages.flatMap((childMessages, index) =>
      flattenFormError(childMessages, `${keysString}-${index}`)
    );
  }

  // is not FieldError
  if (typeof messages === 'object' && messages.type === undefined) {
    return Object.keys(messages).flatMap((key) =>
      flattenFormError(messages[key], `${keysString}-${key}`)
    );
  }

  return [
    {
      key: keysString,
      error: messages as FieldError,
    },
  ];
};

/**
 * nestしたAPI errorをflatにする
 */
const flattenValidatorMessages = (messages: TValidatorMessage): string[] => {
  if (Array.isArray(messages)) {
    return messages.flatMap((message) =>
      typeof message === 'string' ? message : flattenValidatorMessages(message)
    );
  }
  return Object.keys(messages).flatMap((key) =>
    flattenValidatorMessages(messages[key])
  );
};

export {
  ApiErrors,
  FormError,
  getError,
  flattenValidatorMessages,
  getApiErrorMessages,
};
