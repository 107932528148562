import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TFormInputs,
  TFormItems,
  TPriceIsEnabledList,
  TPriceDefaultPrices,
  TSaveResponse,
} from './types';
import { SubmitHandler, useFieldArray } from 'react-hook-form';
import {
  RadioBoxes,
  SingleSelect,
  TextArea,
} from '@/components/shared/Form/Inputs';
import {
  TChoice,
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { ApiErrors as ApiValidationErrors } from '@/components/shared/Form/Errors';
import { removeEmpty } from '@/ts/objectTools';
import SaveApiErrors from '../../../shared/SaveApiErrors/App';
import PriceInputTable from './PriceInputTable';
import { TPriceInput } from './types';
import { calcAssociatedPrice } from './price';
import { getPhotoSizeNameById, initializePriceInput } from './priceSettings';
import {
  isUserTypeGeneral,
  USER_TYPE_ASSOCIATED,
  isPhotoTypeSnap,
} from './constants';
import { postJson, toMessages } from '../../../../ts/useApi';
import { errorToast, successToast } from '../../../../ts/toast';
import { useFormContext } from 'react-hook-form';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { alertApiError } from '../../../../ts/formValidation';

type TSaveFormProps = {
  savePath: string;
  taxRate: number;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  formItems: TFormItems;
  isEnabledList: TPriceIsEnabledList;
  defaultPrices: TPriceDefaultPrices;
  defaultPricesTaxIncluded: TPriceDefaultPrices;
  incentivePaymentType: number | null;
  snapDefaultSizeChoices: TChoice[];
  groupDefaultSizeChoices: TChoice[];
  enableInputTaxIncludedPrice: boolean;
  teacherFlag: boolean;
};

export const findPriceIndex = (
  prices: TPriceInput[],
  userTypeId: number,
  photoTypeId: number,
  photoSizeId: number
): number =>
  prices.findIndex(
    (item) =>
      item.usertypemasterno === userTypeId &&
      item.phototypemasterno === photoTypeId &&
      item.photosizemasterno === photoSizeId
  );

const SaveForm: React.FC<TSaveFormProps> = React.memo(
  ({
    savePath,
    validator: orgValidator,
    snapDefaultSizeChoices,
    groupDefaultSizeChoices,
    formItems,
    incentivePaymentType,
    isEnabledList,
    defaultPrices,
    defaultPricesTaxIncluded,
    enableInputTaxIncludedPrice,
    teacherFlag,
    ...priceTableProps
  }) => {
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [validator, setValidator] = useState<
      TValidatorResponse | TOnlyValidationRuleResponse
    >(orgValidator);
    const [isAutoCalcAssociated, setIsAutoCalcAssociated] =
      useState<boolean>(true);
    const history = useHistory();
    const { photoSizes } = formItems;

    const { handleSubmit, control, watch, setValue, getValues, reset } =
      useFormContext<TFormInputs>();
    const { fields: priceFields } = useFieldArray({
      name: 'prices',
      control,
    });

    const onSubmit: SubmitHandler<TFormInputs> = usePreventDuplicateCall(
      async (formData) => {
        try {
          const data = formData.prices.map((price: TPriceInput) => {
            if (typeof price.price === 'number' && price.price > 0) {
              return price;
            }
            if (price.usertypemasterno === 2 && isAutoCalcAssociated) {
              // 関係者価格かつ自動入力の際
              // usertypemasternoが1でphototypemasternoとphotosizemasternoが一致するデータを検索
              const matchingItem = formData.prices.find(
                (item: TPriceInput) =>
                  item.usertypemasterno === 1 &&
                  item.phototypemasterno === price.phototypemasterno &&
                  item.photosizemasterno === price.photosizemasterno &&
                  typeof item.price === 'number' &&
                  item.price >= 1
              );
              // 一致するデータがあり、priceが1以上の場合はpriceを削除しない
              if (matchingItem) {
                return price;
              }
            }
            delete price.price;
            return price;
          });
          const saveResponse = await postJson<TSaveResponse>(
            savePath,
            removeEmpty({ ...formData, prices: data })
          );
          setErrorMessages([]);
          if (saveResponse.validator.hasError) {
            setValidator(saveResponse.validator);
            alertApiError('登録に失敗しました');
            return;
          }
          successToast('登録しました');
          history.go(0);
        } catch (e) {
          errorToast('エラーが発生しました');
          setErrorMessages(toMessages(e));
        }
      }
    );

    const autoCalcAssociatedPrice = useCallback(
      (generalPrice: Partial<TPriceInput>) => {
        // 責任者価格の取得
        const associatedPriceIndex = findPriceIndex(
          priceFields,
          USER_TYPE_ASSOCIATED,
          generalPrice.phototypemasterno!,
          generalPrice.photosizemasterno!
        );

        // 状態更新後に値を確認する
        setTimeout(() => {
          const associatedPrice = watch(`prices.${associatedPriceIndex}`);
          if (!associatedPrice || !associatedPrice.isEnabled) {
            return;
          }

          // サイズ名を取得
          const photoSizeName = getPhotoSizeNameById(
            photoSizes,
            generalPrice.photosizemasterno!
          )!;
          const value = calcAssociatedPrice(
            generalPrice.price,
            incentivePaymentType,
            photoSizeName,
            teacherFlag
          );
          // 責任者価格update
          setValue(
            `prices.${associatedPriceIndex}.price`,
            value === 0 ? undefined : value
          );
        }, 0); // 次のフレームで実行
      },
      [
        priceFields,
        incentivePaymentType,
        photoSizes,
        setValue,
        watch,
        teacherFlag,
      ]
    );
    const resetPrices = useCallback(
      (
        defaultPhotoSizeId: number,
        isSnap: boolean,
        isSaveInputPrice?: boolean
      ) => {
        priceFields.forEach(
          (
            { usertypemasterno, phototypemasterno, photosizemasterno },
            index
          ) => {
            if (isPhotoTypeSnap(phototypemasterno) !== isSnap) {
              return;
            }
            const newPriceInput = initializePriceInput(
              isEnabledList,
              photoSizes,
              getPhotoSizeNameById(photoSizes, defaultPhotoSizeId)!,
              usertypemasterno,
              phototypemasterno,
              photosizemasterno,
              teacherFlag
            );
            setValue(`prices.${index}.isEnabled`, newPriceInput.isEnabled);
            if (newPriceInput.isEnabled) {
              if (isSaveInputPrice) {
                // NOTE: 価格が入力済みの場合は保持する
                const currentPrice = watch(`prices.${index}.price`);
                setValue(
                  `prices.${index}.price`,
                  currentPrice ? currentPrice : undefined
                );
              } else {
                setValue(`prices.${index}.price`, newPriceInput.price);
              }
            } else {
              setValue(`prices.${index}.price`, undefined);
            }
          }
        );
      },
      [isEnabledList, photoSizes, priceFields, watch, setValue, teacherFlag]
    );

    const resetAllPrices = useCallback(() => {
      resetPrices(watch('basicSizeOfSnap'), true);
      resetPrices(watch('basicSizeOfGroup'), false);
    }, [resetPrices, watch]);

    const clearAllPrices = useCallback(() => {
      const currentPriceFields = getValues('prices').map((price) => ({
        ...price,
        price: null,
      }));
      reset({
        ...getValues(), // 他の値を維持
        prices: currentPriceFields,
      });
    }, [getValues, reset]);

    useEffect(() => {
      const subscription = watch((data, { name }) => {
        if (!name) {
          return;
        }
        if (name.match(/^basicSizeOfSnap$/) && data.basicSizeOfSnap) {
          resetPrices(data.basicSizeOfSnap, true, true);
        }
        if (name.match(/^basicSizeOfGroup$/) && data.basicSizeOfGroup) {
          resetPrices(data.basicSizeOfGroup, false, true);
        }
        if (name === 'isCalculatedByTaxIncludedPrice') {
          resetAllPrices();
        }
        if (isAutoCalcAssociated) {
          const match = name.match(/^prices.(\d+).price$/);
          if (!data.prices || !match) {
            return;
          }
          const index = Number(match[1] as string);
          const price = data.prices[index];
          if (!price || !isUserTypeGeneral(price.usertypemasterno!)) {
            return;
          }
          autoCalcAssociatedPrice(price);
        }
      });
      return () => subscription.unsubscribe();
    }, [
      watch,
      isAutoCalcAssociated,
      autoCalcAssociatedPrice,
      resetPrices,
      resetAllPrices,
    ]);
    // 自動計算のon/off
    const toggleIsAutoCalcAssociated = useCallback(
      (autoCalc: boolean) => {
        setIsAutoCalcAssociated(autoCalc);
        if (autoCalc) {
          // 関係者価格自動計算 off -> on 時に、すべて自動計算を行う
          priceFields.forEach((priceInput, index) => {
            if (!isUserTypeGeneral(priceInput.usertypemasterno!)) {
              return;
            }
            autoCalcAssociatedPrice(watch(`prices.${index}`));
          });
        }
      },
      [priceFields, autoCalcAssociatedPrice, watch]
    );

    return (
      <>
        <SaveApiErrors messages={errorMessages} />
        <ApiValidationErrors {...validator} />
        <form
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="c-frame">
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">
                <label>メモ</label>
              </li>
              <li className="c-dataValue">
                <TextArea name="memo" validator={validator} />
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>関係者の自動入力</label>
                  </li>
                  <li className="c-dataValue">
                    <label className="c-radioLabel">
                      <input
                        type="radio"
                        name="autoCalc"
                        value="auto"
                        className="c-radioBtn"
                        onChange={() => toggleIsAutoCalcAssociated(true)}
                        defaultChecked={true}
                      />
                      <span className="c-label_radioBtn">有</span>
                    </label>
                    <label className="c-radioLabel">
                      <input
                        type="radio"
                        name="autoCalc"
                        value="manual"
                        className="c-radioBtn"
                        onChange={() => toggleIsAutoCalcAssociated(false)}
                      />
                      <span className="c-label_radioBtn">無</span>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
            {enableInputTaxIncludedPrice && (
              <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                <li className="c-label_innerHalf">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>入力方式</label>
                    </li>
                    <RadioBoxes
                      name="isCalculatedByTaxIncludedPrice"
                      validator={validator}
                      choices={[
                        {
                          key: 0,
                          value: '税抜き価格で入力する',
                        },
                        {
                          key: 1,
                          value: '税込み価格で入力する',
                        },
                      ]}
                    />
                  </ul>
                </li>
              </ul>
            )}
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-dataLabel">
                <label>基本サイズ</label>
              </li>
              <li className="c-dataValue">
                <div className="t-bgBox_gray">
                  <ul className="l-flex">
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>スナップ写真</label>
                        </li>
                        <li className="c-dataValue u-mgr_s">
                          <SingleSelect
                            name="basicSizeOfSnap"
                            choices={snapDefaultSizeChoices}
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>集合写真</label>
                        </li>
                        <li className="c-dataValue u-mgr_s">
                          <SingleSelect
                            name="basicSizeOfGroup"
                            choices={groupDefaultSizeChoices}
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <li className="c-dataLabel">価格</li>
              <li className="c-dataValue u-align_right">
                <button
                  type="button"
                  className="p-priceList-link-button"
                  onClick={() => clearAllPrices()}
                >
                  価格をクリア
                </button>
              </li>
            </ul>
            <PriceInputTable
              {...priceTableProps}
              validator={validator}
              formItems={formItems}
              isAutoCalcAssociated={isAutoCalcAssociated}
            />
            <div className="c-section_subTitle_gry u-mgb_m">
              <ul>
                {watch('isCalculatedByTaxIncludedPrice') === 0 && (
                  <li className="p-price-lists">
                    税抜き価格を入力してください。（）内は税込み価格になります。
                  </li>
                )}
                <li className="p-price-lists">
                  販売しないサイズは空欄にしてください。
                </li>
                <li className="p-price-lists">
                  集合写真の取り扱いがない場合は、集合のデフォルトサイズを2LWを選択して、集合価格を全て空白にしてください。
                </li>
              </ul>
            </div>
            <div className="u-align_center u-mgb_m">
              <input
                className="c-btn_large c-btn_primary is-arrow c-input_submit"
                type="submit"
                value="登録する"
              />
            </div>
          </div>
        </form>
      </>
    );
  }
);

export default SaveForm;
