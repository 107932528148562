import React from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';

type Props = {
  type: 'error' | 'warning' | 'success';
  message: string;
  onClose?: () => void;
};

const params = {
  block: {
    error: clsx(styles.baseNotice, styles.errorBlock),
    warning: clsx(styles.baseNotice, styles.warningBlock),
    success: clsx(styles.baseNotice, styles.successBlock),
  },
  listItem: {
    error: clsx(styles.baseListItem, styles.errorListItem),
    warning: clsx(styles.baseListItem, styles.warningListItem),
    success: clsx(styles.baseListItem, styles.successListItem),
  },
};

export const Notice: React.FC<Props> = React.memo(
  ({ type, message, onClose }) => {
    return (
      <div className={params.block[type]}>
        <ul className={`c-${type}_list`}>
          <li className={params.listItem[type]}>{message}</li>
        </ul>
        {onClose && (
          <button className={styles.closeButton} onClick={onClose}>
            &times;
          </button>
        )}
      </div>
    );
  }
);
