import { NotFoundError, GeneralError } from './components/Error';
import Top from './components/pages/Top/App';
import ArrangePhotographerIndex from './components/pages/ArrangePhotographer/Index/App';
import ArrangePhotographerRecommendedPhotographers from './components/pages/ArrangePhotographer/RecommendedPhotographers/App';
import ArrangePhotographerSendOffer from './components/pages/ArrangePhotographer/SendOffer/App';
import EventsIndex from './components/pages/Events/Index/App';
import EventsShow from './components/pages/Events/Show/App';
import EventsFlyer from './components/pages/Events/Flyer/App';
import EventsFlyerMail from './components/pages/Events/Flyer/Mail/App';
import EventsSampleBooksMail from './components/pages/Events/SampleBooks/Mail/App';
import EventsPartnerFlyer from './components/pages/Events/Flyer/Partner/App';
import EventCoverLetter from './components/pages/Events/Flyer/CoverLetter/App';
import EventsCreate from './components/pages/Events/Create/App';
import EventsEdit from './components/pages/Events/Edit/App';
import EventsHiddenPhotographs from './components/pages/Events/HiddenPhotographs/App';
import EventsBulkHiddenPhotographs from './components/pages/Events/BulkHiddenPhotographs/App';
import EventsPeriod from './components/pages/Events/Period/App';
import PhotographsInsert from './components/pages/Photographs/Insert/App';
import PhotographsShow from './components/pages/Photographs/Show/App';
import PhotographsIndex from './components/pages/Photographs/Index/App';
import SocietiesIndex from './components/pages/Societies/Index/App';
import SocietiesShow from './components/pages/Societies/Show/App';
import SocietiesCreate from './components/pages/Societies/Create/App';
import SocietiesEdit from './components/pages/Societies/Edit/App';
import SocietyContractsCreate from './components/pages/SocietyContracts/Create/App';
import SocietyContractsEdit from './components/pages/SocietyContracts/Edit/App';
import LeaderMailaddressConfirm from './components/pages/SocietyContracts/LeaderMailaddressConfirm/App';
import SalesForecastsIndex from './components/pages/SalesForecasts/Index/App';
import BankAccountsShow from './components/pages/BankAccounts/Show/App';
import BankAccountsCreate from './components/pages/BankAccounts/Create/App';
import BankAccountsEdit from './components/pages/BankAccounts/Edit/App';
import PartnersIndex from './components/pages/Partners/Index/App';
import PartnersShow from './components/pages/Partners/Show/App';
import GroupsIndex from './components/pages/Groups/Index/App';
import GroupShow from './components/pages/Groups/Show/App';
import CorporationsShow from './components/pages/Corporation/Show/App';
import CorporationsIndex from './components/pages/Corporation/Index/App';
import CorporationsCreate from './components/pages/Corporation/Create/App';
import CorporationsEdit from './components/pages/Corporation/Edit/App';
import CorporationsSocietiesEdit from './components/pages/Corporation/Societies/Edit/App';
import PartnerIncentivePaymentsIndex from './components/pages/PartnerIncentivePayments/Index/App';
import PricesIndex from './components/pages/Prices/Index/App';
import PricesIndexByGroups from './components/pages/Prices/IndexByGroups/App';
import PartnerIncentivePaymentsShow from './components/pages/PartnerIncentivePayments/Show/App';
import TeacherIncentivePaymentsIndex from './components/pages/TeacherIncentivePayments/Index/App';
import TeacherIncentivePaymentsShow from './components/pages/TeacherIncentivePayments/Show/App';
import CertificationkeysIndex from './components/pages/Certificationkeys/Index/App';
import CertificationkeysShow from './components/pages/Certificationkeys/Show/App';
import CertificationkeysUseraccounts from './components/pages/Certificationkeys/Useraccounts/App';
import KeyBundlesShow from './components/pages/KeyBundles/Show/App';
import SalesIndex from './components/pages/Sales/Index/App';
import ConvertinfoIndexUnbundled from './components/pages/Convertinfos/IndexUnbundled/App';
import OwnerAccountsCreate from './components/pages/OwnerAccounts/Create/App';
import OwnerAccountsEdit from './components/pages/OwnerAccounts/Edit/App';
import OwnerAccountsChangePasswd from './components/pages/OwnerAccounts/ChangePasswd/App';
import OwnerInvitationInput from './components/pages/OwnerAccounts/InvitationMailInput/App';
import OwnerInvitationConfirm from './components/pages/OwnerAccounts/InvitationMailConfirm/App';
import GroupsSocietiesIndex from './components/pages/GroupsSocieties/Index/App';
import AdministratorIndex from './components/pages/Administrator/Index/App';
import AdministratorCreate from './components/pages/Administrator/Create/App';
import AdministratorEdit from './components/pages/Administrator/Edit/App';
import AdministratorChangePasswd from './components/pages/Administrator/ChangePasswd/App';
import SendEventSchedulePdf from './components/pages/EventSchedulePdf/Send/App';
import PhotographersShow from './components/pages/Photographers/Show/App';
import PhotographersCreate from './components/pages/Photographers/Create/App';
import PhotographersEdit from './components/pages/Photographers/Edit/App';
import PhotographerIndex from './components/pages/Photographers/Index/App';
import { PhotographersNgPairs } from './components/pages/Photographers/NgPairs';
import PhotographerOrganizationCreate from './components/pages/PhotographerOrganizations/Create/App';
import PhotographerOrganizationEdit from './components/pages/PhotographerOrganizations/Edit/App';
import PhotographerOrganizationShow from './components/pages/PhotographerOrganizations/Show/App';
import PhotographerOrganizationIndex from './components/pages/PhotographerOrganizations/Index/App';
import PhotographerSchedule from './components/pages/PhotographerSchedule/Index/App';
import { Create as PhotographerPaymentsAdjustmentPaymentCreate } from '@/components/pages/PhotographerPayments/AdjustmentPayment/Create';
import { IndexPage as PhotographerPaymentsAdjustmentPaymentIndex } from './components/pages/PhotographerPayments/AdjustmentPayment/IndexPage';
import { IndexPage as PhotographerPaymentsPenaltiesIndex } from './components/pages/PhotographerPayments/Penalties/IndexPage';
import { IndexPage as PhotographerPaymentsAwardsIndex } from './components/pages/PhotographerPayments/Awards/IndexPage';
import { Create as PhotographerPaymentsAwardsCreate } from '@/components/pages/PhotographerPayments/Awards/Create';
import { IndexPage as PhotographerPaymentsRequestFeesIndex } from './components/pages/PhotographerPayments/RequestFees/IndexPage';
import { IndexPage as PhotographerPaymentsCompensationsIndex } from './components/pages/PhotographerPayments/Compensations/IndexPage';
import { IndexPage as PhotographerPaymentsNonEventRelatedExpensesIndex } from './components/pages/PhotographerPayments/NonEventRelatedExpenses/IndexPage';
import { BulkEdit as PhotographerPaymentsRequestFeesBulkEdit } from './components/pages/PhotographerPayments/RequestFees/BulkEdit';
import { Edit as PhotographerPaymentsCompensationsEdit } from './components/pages/PhotographerPayments/Compensations/Edit';
import { Create as PhotographerPaymentsNonEventRelatedExpensesCreate } from '@/components/pages/PhotographerPayments/NonEventRelatedExpenses/Create';
import { Create as PhotographerPaymentsPenaltiesCreate } from './components/pages/PhotographerPayments/Penalties/Create';
import { IndexPage as PhotographerPaymentsMonthlyClosingsIndex } from './components/pages/PhotographerPayments/MonthlyClosings/IndexPage';
import { IndexPage as PhotographerPaymentsTransfersIndex } from './components/pages/PhotographerPayments/Transfers/IndexPage';
import { Summary as PhotographerPaymentsTransfersSummary } from './components/pages/PhotographerPayments/Transfers/Summary';
import { Edit as PhotographerPaymentsExpensesEdit } from './components/pages/PhotographerPayments/Expenses/Edit';
import { Create as PhotographerPaymentsExpensesCreate } from './components/pages/PhotographerPayments/Expenses/Create';
import { IndexPage as PhotographerPaymentsExpensesIndex } from './components/pages/PhotographerPayments/Expenses/IndexPage';
import { IndexPage as PhotographerPaymentsFinance } from './components/pages/PhotographerPayments/Finance';
import { IndexPage as PhotographerPaymentsZengin } from './components/pages/PhotographerPayments/Zengin';
import { IndexPage as PhotographerPaymentsIndex } from './components/pages/PhotographerPayments/Payments/IndexPage';
import { IndexPage as PhotographerPaymentsTransportationExpensesIndex } from './components/pages/PhotographerPayments/TransportationExpenses/IndexPage';
import OrderInfo from './components/pages/OrderInfo/Index/App';
import OrderShow from './components/pages/OrderInfo/Show/App';
import Header from './components/Header/App';
import Sidemenu from './components/Sidemenu/App';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { AuthProvider } from './components/AuthProvider';
import { PhotoSearch } from './components/pages/PhotoSearch';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { OrderInfoBundle } from './components/pages/OrderInfo/Bundle';
import { MailLog } from './components/pages/MailLog';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.02,
});

const fallback = (
  <div className="l-content_wrap">
    <GeneralError
      code={''}
      message="処理中にエラーが発生しました。システム担当者にご連絡ください。"
    />
  </div>
);

function useIsPCView() {
  const isPC = () => window.innerWidth > 767;
  const [isPCView, setIsPCView] = useState(isPC());
  useEffect(() => {
    const listner = () => setIsPCView(isPC());
    window.addEventListener('resize', listner);
    return () => {
      window.removeEventListener('resize', listner);
    };
  }, []);
  return isPCView;
}

const App: React.FC = () => {
  const isPCView = useIsPCView();
  return (
    <Sentry.ErrorBoundary fallback={fallback}>
      <HelmetProvider>
        <Helmet>
          <title>千管理画面</title>
        </Helmet>
        <Router>
          <Switch>
            <Route path="/error/auth">
              <div className="l-content_wrap">
                <GeneralError
                  code="403"
                  message="ログインに失敗しました。再度ログインをお試しください"
                />
              </div>
            </Route>
            <Route>
              <AuthProvider>
                <Header />
                <main>
                  {!isPCView && <Sidemenu />}
                  <Routes />
                </main>
                <ToastContainer
                  position="bottom-center"
                  hideProgressBar={true}
                  theme="colored"
                  autoClose={5000}
                />
              </AuthProvider>
            </Route>
          </Switch>
        </Router>
      </HelmetProvider>
    </Sentry.ErrorBoundary>
  );
};

const Routes: React.FC = () => {
  const location = useLocation();
  return (
    <Sentry.ErrorBoundary key={location.pathname} fallback={fallback}>
      <div className="l-content_wrap">
        <Switch>
          <Route path="/arrange_photographer/recommended_photographers">
            <ArrangePhotographerRecommendedPhotographers />
          </Route>
          <Route path="/arrange_photographer/send_offer">
            <ArrangePhotographerSendOffer />
          </Route>
          <Route path="/arrange_photographer">
            <ArrangePhotographerIndex />
          </Route>
          <Route path="/send_event_schedule_pdf">
            <SendEventSchedulePdf />
          </Route>
          <Route path="/categories/:categoryId/photographs/insert">
            <PhotographsInsert />
          </Route>
          <Route path="/photographs/search">
            <PhotoSearch />
          </Route>
          <Route path="/photographs/:id">
            <PhotographsShow />
          </Route>
          <Route path="/events/:eventId/photographs">
            <PhotographsIndex />
          </Route>
          <Route path="/photographs">
            <PhotographsIndex />
          </Route>
          <Route path="/events/:id/flyer/mail">
            <EventsFlyerMail />
          </Route>
          <Route path="/events/:id/samplebook/mail">
            <EventsSampleBooksMail />
          </Route>
          <Route path="/events/:id/eventflyer">
            <EventsFlyer />
          </Route>
          <Route path="/events/:id/cover_letter">
            <EventCoverLetter />
          </Route>
          <Route path="/events/event_partner_flyer/:id">
            <EventsPartnerFlyer />
          </Route>
          <Route path="/events/:id/hidden_photographs">
            <EventsHiddenPhotographs />
          </Route>
          <Route path="/events/:id/bulk_hidden_photographs">
            <EventsBulkHiddenPhotographs />
          </Route>
          <Route path="/events/period">
            <EventsPeriod />
          </Route>
          <Route path="/events/:eventId">
            <EventsShow />
          </Route>
          <Route path="/events">
            <EventsIndex />
          </Route>
          <Route path="/society_contracts/:society_contract_id/plans/:plan_id/events/create">
            <EventsCreate />
          </Route>
          <Route path="/society_contracts/:society_contract_id/plans/:plan_id/events/:event_no/edit">
            <EventsEdit />
          </Route>
          <Route path="/societies/create">
            <SocietiesCreate />
          </Route>
          <Route path="/societies/:id/edit">
            <SocietiesEdit />
          </Route>
          <Route path="/society_contracts/:society_contract_id/owneraccounts/create">
            <OwnerAccountsCreate />
          </Route>
          <Route path="/owneraccounts/:ownerno/edit">
            <OwnerAccountsEdit />
          </Route>
          <Route path="/owneraccounts/:ownerno/changepasswd">
            <OwnerAccountsChangePasswd />
          </Route>
          <Route path="/owneraccounts/:ownerno/invitation_mail/input">
            <OwnerInvitationInput />
          </Route>
          <Route path="/owneraccounts/:ownerno/invitation_mail/confirm">
            <OwnerInvitationConfirm />
          </Route>
          <Route path="/societies/:societyId/society_contracts/create/:societyContractType(direct|teacher)">
            <SocietyContractsCreate />
          </Route>
          <Route path="/societies/:societyId/sales_forecasts/">
            <SalesForecastsIndex />
          </Route>
          <Route path="/societies/:id">
            <SocietiesShow />
          </Route>
          <Route path="/societies">
            <SocietiesIndex />
          </Route>
          <Route path="/partners/:id">
            <PartnersShow />
          </Route>
          <Route path="/partners">
            <PartnersIndex />
          </Route>
          <Route path="/groups/:id/prices">
            <PricesIndexByGroups />
          </Route>
          <Route path="/groups/:id">
            <GroupShow />
          </Route>
          <Route path="/groups">
            <GroupsIndex />
          </Route>
          <Route path="/corporations/create">
            <CorporationsCreate />
          </Route>
          <Route path="/corporations/edit/:id">
            <CorporationsEdit />
          </Route>
          <Route path="/corporations/:id/societies/edit">
            <CorporationsSocietiesEdit />
          </Route>
          <Route path="/corporations/:id">
            <CorporationsShow />
          </Route>
          <Route path="/corporations">
            <CorporationsIndex />
          </Route>
          <Route path="/society_contracts/:contractId/bank_accounts/create">
            <BankAccountsCreate />
          </Route>
          <Route path="/society_contracts/:contractId/bank_accounts/edit">
            <BankAccountsEdit />
          </Route>
          <Route path="/society_contracts/:contractId/bank_accounts">
            <BankAccountsShow />
          </Route>
          <Route path="/partner_incentive_payments/:id">
            <PartnerIncentivePaymentsShow />
          </Route>
          <Route path="/partner_incentive_payments">
            <PartnerIncentivePaymentsIndex />
          </Route>
          <Route path="/society_contracts/:societyContractId/plans/:planId/prices">
            <PricesIndex />
          </Route>
          <Route path="/society_contracts/:id/edit">
            <SocietyContractsEdit />
          </Route>
          <Route path="/teacher_incentive_payments/:id">
            <TeacherIncentivePaymentsShow />
          </Route>
          <Route path="/teacher_incentive_payments">
            <TeacherIncentivePaymentsIndex />
          </Route>
          <Route path="/certificationkeys/:id/useraccounts">
            <CertificationkeysUseraccounts />
          </Route>
          <Route path="/certificationkeys/:id">
            <CertificationkeysShow />
          </Route>
          <Route path="/certificationkeys">
            <CertificationkeysIndex />
          </Route>
          <Route path="/key_bundles/:bundleId">
            <KeyBundlesShow />
          </Route>
          <Route path="/sales">
            <SalesIndex />
          </Route>
          <Route path="/convertinfos/unbundled">
            <ConvertinfoIndexUnbundled />
          </Route>
          <Route path="/groups_societies">
            <GroupsSocietiesIndex />
          </Route>
          <Route path="/administrator/create">
            <AdministratorCreate />
          </Route>
          <Route path="/administrator/:ownerno/edit">
            <AdministratorEdit />
          </Route>
          <Route path="/administrator/:ownerno/change_passwd">
            <AdministratorChangePasswd />
          </Route>
          <Route path="/administrator">
            <AdministratorIndex />
          </Route>
          <Route path="/photographer_organizations/create">
            <PhotographerOrganizationCreate />
          </Route>
          <Route path="/photographer_organizations/:id/edit">
            <PhotographerOrganizationEdit />
          </Route>
          <Route path="/photographer_organizations/:id">
            <PhotographerOrganizationShow />
          </Route>
          <Route path="/photographer_organizations">
            <PhotographerOrganizationIndex />
          </Route>
          <Route path="/photographer_schedule">
            <PhotographerSchedule />
          </Route>
          <Route path="/photographers/create">
            <PhotographersCreate />
          </Route>
          <Route path="/photographers/ng_pairs">
            <PhotographersNgPairs />
          </Route>
          <Route path="/photographers/:photographerId/edit">
            <PhotographersEdit />
          </Route>
          <Route path="/photographers/:id">
            <PhotographersShow />
          </Route>
          <Route path="/photographers">
            <PhotographerIndex />
          </Route>
          <Route path="/photographer_payments/adjustment/create">
            <PhotographerPaymentsAdjustmentPaymentCreate />
          </Route>
          <Route path="/photographer_payments/adjustment">
            <PhotographerPaymentsAdjustmentPaymentIndex />
          </Route>
          <Route path="/photographer_payments/non_event_related_expenses/create">
            <PhotographerPaymentsNonEventRelatedExpensesCreate />
          </Route>
          <Route path="/photographer_payments/non_event_related_expenses">
            <PhotographerPaymentsNonEventRelatedExpensesIndex />
          </Route>
          <Route path="/photographer_payments/awards/create">
            <PhotographerPaymentsAwardsCreate />
          </Route>
          <Route path="/photographer_payments/awards">
            <PhotographerPaymentsAwardsIndex />
          </Route>
          <Route path="/photographer_payments/penalties/create">
            <PhotographerPaymentsPenaltiesCreate />
          </Route>
          <Route path="/photographer_payments/penalties">
            <PhotographerPaymentsPenaltiesIndex />
          </Route>
          <Route path="/photographer_payments/request_fees/bulk_edit">
            <PhotographerPaymentsRequestFeesBulkEdit />
          </Route>
          <Route path="/photographer_payments/request_fees">
            <PhotographerPaymentsRequestFeesIndex />
          </Route>
          <Route path="/photographer_payments/compensations/edit">
            <PhotographerPaymentsCompensationsEdit />
          </Route>
          <Route path="/photographer_payments/compensations">
            <PhotographerPaymentsCompensationsIndex />
          </Route>
          <Route path="/photographer_payments/monthly_closings">
            <PhotographerPaymentsMonthlyClosingsIndex />
          </Route>
          <Route path="/order_infos/bundle/:id">
            <OrderInfoBundle />
          </Route>
          <Route path="/order_infos/:id">
            <OrderShow />
          </Route>
          <Route path="/order_infos">
            <OrderInfo />
          </Route>
          <Route path="/photographer_payments/transfers/:transferId">
            <PhotographerPaymentsTransfersSummary />
          </Route>
          <Route path="/photographer_payments/transfers">
            <PhotographerPaymentsTransfersIndex />
          </Route>
          <Route path="/photographer_payments/transportation_expenses">
            <PhotographerPaymentsTransportationExpensesIndex />
          </Route>
          <Route path="/photographer_payments/expenses/edit">
            <PhotographerPaymentsExpensesEdit />
          </Route>
          <Route path="/photographer_payments/expenses/create">
            <PhotographerPaymentsExpensesCreate />
          </Route>
          <Route path="/photographer_payments/expenses">
            <PhotographerPaymentsExpensesIndex />
          </Route>
          <Route path="/photographer_payments/finance">
            <PhotographerPaymentsFinance />
          </Route>
          <Route path="/photographer_payments/zengin">
            <PhotographerPaymentsZengin />
          </Route>
          <Route path="/photographer_payments/">
            <PhotographerPaymentsIndex />
          </Route>
          <Route path="/leader_mailadress_confirm">
            <LeaderMailaddressConfirm />
          </Route>
          <Route path="/mail_log">
            <MailLog />
          </Route>
          <Route path="/" exact>
            <Top />
          </Route>
          <Route>
            <NotFoundError />
          </Route>
        </Switch>
      </div>
    </Sentry.ErrorBoundary>
  );
};

export default App;
