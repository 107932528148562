import React from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { NgPairAddFormParams } from '../../hooks';
import { ContentPanel } from '@/components/shared/ContentPanel';
import { useAddNgPair } from './hooks';
import { FormItem } from '@/components/shared/Form/FormItem';
import Stack from '@/components/shared/Stack';
import clsx from 'clsx';
import { FormActions } from '@/components/shared/Form/FormActions';
import { Button } from '@/components/shared/Button';

export type Props = {
  loading: boolean;
  doSubmit: SubmitHandler<NgPairAddFormParams>;
};

export const AddPanel = React.memo<Props>(function AddPanel({
  loading,
  doSubmit,
}) {
  const { formMethods, handleSubmitWrapper } = useAddNgPair(doSubmit);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = formMethods;

  return (
    <ContentPanel title="NG組み合わせの登録">
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleSubmitWrapper)}>
          <FormItem title="カメラマンID:1" wideTitle={true}>
            <Stack
              direction="column"
              spacing={0}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <input
                type="text"
                {...register('photographerId')}
                className={clsx(
                  'c-input_plane',
                  errors.photographerId && 'is-error'
                )}
                placeholder="カメラマンID:1"
              />
              <div className="c-inputError">
                <p className="c-inputError_item">
                  {errors.photographerId?.message}
                </p>
              </div>
            </Stack>
          </FormItem>
          <FormItem title="カメラマンID:2" wideTitle={true}>
            <Stack
              direction="column"
              spacing={0}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <input
                type="text"
                {...register('targetPhotographerId')}
                className={clsx(
                  'c-input_plane',
                  errors.targetPhotographerId && 'is-error'
                )}
                placeholder="カメラマンID:2"
              />
              <div className="c-inputError">
                <p className="c-inputError_item">
                  {errors.targetPhotographerId?.message}
                </p>
              </div>
            </Stack>
          </FormItem>
          <FormActions>
            <Button
              value="登録"
              type="submit"
              variant="search"
              loading={loading}
            />
          </FormActions>
        </form>
      </FormProvider>
    </ContentPanel>
  );
});
