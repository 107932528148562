import React from 'react';
import { useDialog } from './hooks';
import styles from './index.module.scss';
import Stack from '../Stack';
import { Button } from '../Button';

type Props = {
  open: boolean;
  body: string;
  closeButtonLabel?: string;
  submitButtonLabel?: string;
  onClose?: () => void;
  onSubmit?: () => void;
};

export const Dialog = React.memo<Props>(function Dialog({
  open,
  body,
  closeButtonLabel = 'キャンセル',
  submitButtonLabel = 'OK',
  onClose,
  onSubmit,
}) {
  const { dialogRef } = useDialog({ open });

  return (
    <dialog ref={dialogRef} className={styles.dialog}>
      <div className={styles.inner}>
        <div className={styles.body}>{body}</div>
        <Stack justifyContent="flex-end">
          {onClose && (
            <Button
              value={closeButtonLabel}
              type="button"
              variant="outline"
              onClick={onClose}
            />
          )}
          {onSubmit && (
            <Button
              value={submitButtonLabel}
              type="button"
              variant="search"
              onClick={onSubmit}
            />
          )}
        </Stack>
      </div>
    </dialog>
  );
});
